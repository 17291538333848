@use '@angular/material' as mat;
@import '../../../scss/values';
@import '../../../scss/mixins/mobile-devices';

.login--page {
  display: flex;
  align-items: stretch;
  justify-content: center;
  min-height: 100%;
  height: auto;
  overflow: auto;
  background-color: var(--login-page-overwrite-black-theme-background-color);

  .form-section {
    flex: 1;
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: 490px;

    .content-wrapper {
      display: flex;
      margin: 0 auto;
      width: 100%;
      height: 100%;

      .large-shape,
      .small-shape {
        display: none;
      }
    }
  }

  .login-form {
    @include mat.form-field-density(-2);
    display: flex;
    flex-direction: column;
    padding: 50px 38px;
    width: 100%;
    margin: 0 auto;

    .logo-wrapper {
      margin-bottom: min(5%, 20px);

      .logo {
        width: 73px;
        height: 23px;
      }
    }

    .form-wrapper {
      flex-direction: column;
      margin: auto 0 auto 0;
      max-height: 445px;
    }

    h2 {
      font-weight: 700;
      font-size: 28px;
      line-height: 52px;

      span {
        display: block;

        &.wellcome {
          color: var(--login-main-colorful-font-color);
        }

        &.project-name {
          color: var(--login-base-font-color);
        }
      }
    }

    form {
      margin-top: 80px;
      position: relative;

      .form-group {
        --mat-form-field-container-text-size: 14px;
        margin-bottom: 15px;
        height: auto;
        position: relative;

        &.email-control {
          margin-bottom: 50px;
        }

        .login-form-field.mat-mdc-form-field {
          .mdc-text-field--outlined {
            --mat-form-field-outlined-label-text-populated-size: 14px;
            --mdc-outlined-text-field-label-text-size: 14px;
            --mdc-outlined-text-field-outline-color: rgb(0 0 0 / 0.12);
            --mdc-outlined-text-field-hover-outline-color: rgb(0 0 0 / 0.2);
            --mdc-outlined-text-field-outline-width: 1px;
            --mdc-outlined-text-field-focus-outline-width: 2px;
          }

          .login-form-input {
            color: var(--login-page-font-input-color);
            padding: 0;
            height: auto;
          }
        }
      }

      .error-container.upper {
        position: absolute;
        top: -18px;
        width: 100%;

        .mat-error {
          width: 100%;
        }

        .show-error {
          position: absolute;
          bottom: 0;
          padding-top: 8px;
        }
      }

      .mat-flat-button .mat-button-wrapper,
      .mat-mdc-unelevated-button {
        --mdc-filled-button-label-text-color: var(--white-color);
        --mdc-filled-button-container-color: var(--primary-btn-bg);
        --mdc-text-button-container-height: 48px;

        display: flex;
        justify-content: center;
        padding: 15px 0;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
      }

      .white-spinner {
        margin: 0 auto;
        height: 18px;
        transition: 0.2s;
        overflow: hidden;

        svg {
          position: absolute;
        }
      }

      .mat-button-ripple.mat-ripple:not(:empty) {
        height: 48px;
        display: none;
        transform: none;
      }

      .additional-links {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 31px 0;
        display: flex;
        justify-content: flex-end;

        a {
          color: var(--login-base-font-color);
          text-decoration: none;
          margin: 0;
          transition: 0.3s all;
          &:hover {
            transition: 0.3s all;
            color: var(--primary-color);
          }
        }
      }
    }
  }
}

@media screen and (min-width: $mobile-min-375px-width-trigger) {
  .login-form {
    h2 {
      font-size: 35px;
    }
  }
}

@media screen and (min-width: $mobile-min-425px-width-trigger) {
  .login--page .login-form {
    h2 {
      font-size: 40px;
    }
  }
}

@media screen and (min-width: $tablet-min-width-trigger) {
  .login--page {
    position: relative;
    background-color: var(--login-page-background-color);
    height: 100%;

    .form-section {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      .content-wrapper {
        position: relative;
        max-width: 490px;
        min-height: 550px;
        height: 80%;
        max-height: 720px;

        .large-shape,
        .small-shape {
          display: block;
          position: absolute;
          background: var(--login-page-shapes-bg-color);
          border-radius: 10px;
        }

        .large-shape {
          width: 181px;
          height: 181px;
          top: -75px;
          left: -81px;
        }

        .small-shape {
          width: 126px;
          height: 126px;
          bottom: -58px;
          right: -48px;
        }

        .login-form {
          display: flex;
          position: relative;
          border-radius: 5px;
          padding: 60px 70px 60px 70px;
          background-color: var(--white-color);
          height: 100%;

          .logo-wrapper {
            .logo {
              width: 85px;
              height: 23px;
            }
          }

          form {
            margin-top: 70px;
          }

          .form-wrapper {
            margin: auto 0 0 0;
          }
        }
      }
    }

    @include ipad-mini-landscape {
      height: 100%;

      .form-section {
        margin: 0;
        height: 100%;

        .content-wrapper {
          .small-shape,
          .large-shape {
            display: none;
          }

          .login-form {
            padding: 30px 60px;
          }
        }
      }
    }

    @include ipad-mini-2021-landscape {
      height: 100%;

      .form-section {
        margin: 0;
        height: 100%;

        .content-wrapper {
          .small-shape,
          .large-shape {
            display: none;
          }

          .login-form {
            padding: 20px 70px 20px 70px;
          }
        }
      }
    }

    @include tab7s-landscape() {
      .login--page {
        .form-section {
          margin: 0px 0;
          height: 100%;

          .content-wrapper {
            .small-shape,
            .large-shape {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $desktop-min-width-trigger) {
  .login--page {
    flex-direction: row;
    padding: 0;
    overflow: hidden;
    background-color: var(--login-page-overwrite-black-theme-background-color);

    .form-section {
      margin: auto;
      max-width: 44%;

      .content-wrapper {
        margin: 0;
        max-height: 720px;

        .large-shape,
        .small-shape {
          display: none;
        }

        .login-form {
          width: 100%;
          min-height: 720px;
          border-radius: 0;
          margin: 0;
          overflow: hidden;
          padding: 80px 70px 90px 70px;

          .form-wrapper {
            max-width: 350px;
          }
        }
      }
    }

    .img-section {
      width: 56%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: var(--login-page-background-color);
    }

    .img-wrapper {
      width: 100%;
      height: 100%;
      background: url('../../../assets/img/login-page-image.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }

  @include desktop-width-and-to800px-hight-monitor() {
    .login--page {
      height: 100%;

      .form-section {
        .content-wrapper .login-form {
          min-height: -webkit-fit-content;
          min-height: -moz-fit-content;
          min-height: fit-content;
          padding: 20px 70px 20px 70px;

          .form-wrapper {
            margin: 0;
          }
        }
      }
    }
  }
}
